import * as React from "react";

const AfspraakForm = () => (
  <div className="row justify-content-center">
      <div className="col-xl-6 col-lg-8">
        <form
          name="contact"
          method="POST"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          action="/success">

          <input type="hidden" name="bot-field" />
          <input type="hidden" name="form-name" value="contact" />

          <input
            className="form-control form-control-lg form-control-hh"
            name="naam"
            id="naam"
            type="text"
            required
            placeholder="Naam (verplicht)"
          />
          <input
            className="form-control form-control-lg form-control-hh"
            name="email"
            type="email"
            id="email"
            required
            placeholder="E-mailadres (verplicht)"
          />
          <input
            className="form-control form-control-lg form-control-hh"
            name="telefoonnr"
            id="telefoonnr"
            type="text"
            placeholder="Telefoonnr"
          />
          <input
            className="form-control form-control-lg form-control-hh"
            name="straathuisnr"
            id="straathuisnr"
            type="text"
            required
            placeholder="Straat en Huisnr (verplicht)"
          />
          <input
            className="form-control form-control-lg form-control-hh"
            name="postcode"
            id="postcode"
            type="text"
            required
            placeholder="Postcode (verplicht)"
          />
          <input
            className="form-control form-control-lg form-control-hh"
            name="woonplaats"
            id="woonplaats"
            type="text"
            required
            placeholder="Woonplaats (verplicht)"
          />
          <input
            className="form-control form-control-lg form-control-hh"
            name="merktoestel"
            id="merktoestel"
            type="text"
            placeholder="Merk van het toestel"
          />
          <input
            className="form-control form-control-lg form-control-hh"
            name="typetoestel"
            id="typetoestel"
            type="text"
            placeholder="Type toestel"
          />
          <div className="mb-3">
            <fieldset>
              <legend>Type Afspraak</legend>
              <div>
                <div className="form-check form-check-inline">
                  <label className="form-check-label">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="typeafspraak"
                      id="onderhoud"
                      value="Onderhoud" />
                    Onderhoud
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <label className="form-check-label">
                    <input
                        className="form-check-input"
                        type="checkbox"
                        name="typeafspraak"
                        id="reparatie"
                        value="Reparatie" />
                    Reparatie
                  </label>
                </div>
              </div>
            </fieldset>
          </div>
          <div className="mb-3">
            <fieldset>
              <legend>Voorkeur dag afspraak (graag meerdere)</legend>
            <div>
              <div className="form-check form-check-inline">
                <label className="form-check-label"><input
                  className="form-check-input"
                  type="checkbox"
                  name="dagafspraak"
                  id="dagafspraak_ma"
                  value="Maandag" />
                Maandag</label>
              </div>
              <div className="form-check form-check-inline">
                <label className="form-check-label"><input
                  className="form-check-input"
                  type="checkbox"
                  name="dagafspraak"
                  id="dagafspraak_di"
                  value="Dinsdag" />
                Dinsdag</label>
              </div>
              <div className="form-check form-check-inline">
                <label className="form-check-label"><input
                  className="form-check-input"
                  type="checkbox"
                  name="dagafspraak"
                  id="dagafspraak_woe"
                  value="Woensdag" />
                Woensdag</label>
              </div>
              <div className="form-check form-check-inline">
                <label className="form-check-label"><input
                  className="form-check-input"
                  type="checkbox"
                  name="dagafspraak"
                  id="dagafspraak_don"
                  value="Donderdag" />
                Donderdag</label>
              </div>
              <div className="form-check form-check-inline">
                <label className="form-check-label"><input
                  className="form-check-input"
                  type="checkbox"
                  name="dagafspraak"
                  id="dagafspraak_vrij"
                  value="Vrijdag" />
                Vrijdag</label>
              </div>
            </div>
            </fieldset>
          </div>
          <textarea
            className="form-control form-control-lg form-control-hh"
            name="bericht"
            rows="4"
            cols="50"
            id="bericht"
            required
            placeholder="Bericht (verplicht)"
          ></textarea>
          <button className="afspraak-button" type="submit">
            Verzenden
          </button>
        </form>
      </div>
  </div>
);

AfspraakForm.propTypes = {
};

export default AfspraakForm;

