import React from 'react'
import PropTypes from 'prop-types'
import Layout from '../components/Layout'
import { graphql } from "gatsby";
import AfspraakForm from '../components/AfspraakForm';

// eslint-disable-next-line
export const AfspraakPageTemplate = class extends React.Component {

	componentDidMount() {
	}

	render() {
		return (
      <section className='container'>
				<h1 className="title mt-4 mb-3"><b>Maak een afspraak:</b></h1>
        <AfspraakForm />
      </section>
    );
  }
}

AfspraakPageTemplate.propTypes = {
	data: PropTypes.shape({
		markdownRemark: PropTypes.shape({
			frontmatter: PropTypes.object,
		}),
	}),
};

const AfspraakPage = ({data}) => {
	const { frontmatter } = data.markdownRemark;
	return (
		<Layout pageTitle='Hans Haardenservice • Afspraak' pageDescription={frontmatter.description}>
			<AfspraakPageTemplate />
		</Layout>
	)
};

AfspraakPage.propTypes = {
	data: PropTypes.shape({
		markdownRemark: PropTypes.shape({
			frontmatter: PropTypes.object,
		}),
	}),
};

export default AfspraakPage

export const pageQuery = graphql`
  query AfspraakPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "afspraak-page" } }) {
      frontmatter {
        title
				description
      }
    }
  }
`;
